@import '../variables'

.article-preview-container

  > .article-preview
    position: relative
    height: 20em
    overflow: hidden
    background-color: $color-white

    > .blur-bot
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 5em
      background: linear-gradient(0deg, $color-white 20%, $color-transparent)
      pointer-events: none

  > .read-more
    display: block
    pointer-events: all
    text-decoration: none
    text-align: right
    padding-top: 0.5em
    font-weight: $font-weight-bold
    color: $color-orange
    opacity: 0.75

    &:hover
      opacity: 1