@import '../variables'
@import '../core/article-preview'

#latest-articles > #splide-container
  padding: 0 0 3em 0

  .articles-arrows
    position: absolute
    top: -2em
    right: 0
    width: 5em

  .articles-arrow > svg
    fill: $color-orange

  .articles-page
    background-color: $color-orange
    opacity: 0.5

    &:hover
      opacity: 0.75

    &.is-active
      opacity: 1

@media only screen and (min-width: $breakpoint-desktop)
  #latest-articles > #splide-container
    padding: 0 3em 3em 3em

    .articles-arrows
      position: unset
      top: unset
      right: unset
      width: auto